import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import slugify from "slugify"
import { SRLWrapper } from "simple-react-lightbox"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const query = graphql`
  {
    allContentfulProjects(sort: { fields: order, order: DESC }) {
      nodes {
        id
        title
        url
        shortDescription
        separatePage
        projectImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1000
            placeholder: TRACED_SVG
          )
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  ctr: {
    paddingTop: "7.25rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  h1: {
    fontSize: "2.9375rem",
    fontWeight: 400,
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  hr: {
    maxWidth: "25%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    },
  },
  projectImageCtr: {
    maxHeight: 800,
    borderRadius: 15,
    paddingTop: "4em",
    boxShadow: "0 12px 64px 0 rgb(0 0 0 / 13%)",
    transition: "0.5s",
    marginBottom: "4.75rem",
    marginLeft: "2em",
    objectFit: "contain",
    "&:hover": {
      boxShadow: "0 24px 32px 0 rgb(0 0 0 / 16%)",
      transform: "translateY(-5px)",
    },
    "& img": {
      maxWidth: 1000,
      maxHeight: 750,
      borderRadius: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
      marginTop: "3.5em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  altProjectImageCtr: {
    maxHeight: 800,
    borderRadius: 15,
    paddingTop: "4em",
    boxShadow: "0 12px 64px 0 rgb(0 0 0 / 13%)",
    transition: "0.5s",
    marginBottom: "4.75rem",
    marginRight: "2em",
    objectFit: "contain",
    "&:hover": {
      boxShadow: "0 24px 32px 0 rgb(0 0 0 / 16%)",
      transform: "translateY(-5px)",
    },
    "& img": {
      borderRadius: 15,
      cursor: "pointer",
      objectFit: "contain",
      maxWidth: 1000,
      maxHeight: 750,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
      marginTop: "5.5em",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  projectTitle: {
    fontSize: "2rem",
    fontFamily: "Open Sans, Work Sans, sans-serif",
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.35rem",
    },
  },
  projectTxtCtr: {
    paddingLeft: "3.75rem",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
    },
  },
  projectDescription: {
    [theme.breakpoints.down("md")]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  altProjectTxtCtr: {
    paddingRight: "3.75rem",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem",
    },
  },
  projectBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    fontFamily: "Montserrat, Helvetica Neue",
    fontSize: "1.25rem",
    marginRight: "auto",
    marginTop: "10%",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.muted,
      boxShadow: "0 12px 24px 0 rgb(0 0 0 / 14%)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  altProjectBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    fontFamily: "Montserrat, Helvetica Neue",
    fontSize: "1.25rem",
    marginLeft: "auto",
    marginTop: "10%",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.muted,
      boxShadow: "0 12px 24px 0 rgb(0 0 0 / 14%)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  projectDivider: {
    opacity: 0.5,
    maxWidth: "70%",
    marginBottom: "5rem",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("lg")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: "75%",
      marginTop: "2em",
      marginBottom: 0,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "3em",
      marginBottom: 0,
      maxWidth: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "3em",
      marginBottom: 0,
    },
  },
  projectAboutMargin: {
    marginTop: "25%",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
}))
const options = {
  settings: {
    overlayColor: "rgba(19, 37, 50, .95)",
  },
}

export default function ProjectsIndex() {
  const theme = useTheme()
  const classes = useStyles()
  const [activeColor, setActiveColor] = React.useState(null)
  const {
    allContentfulProjects: { nodes: projects },
  } = useStaticQuery(query)
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <SRLWrapper options={options}>
      <Grid container className={classes.ctr} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            className={classes.h1}
            gutterBottom
          >
            Project Showcase
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.hr} />
        </Grid>
        <Grid item xs={12}>
          <Grid item container direction="row" justifyContent="center">
            {projects.map((project, i) => {
              const projectLink = project.separatePage ? true : false
              const image = getImage(project.projectImage)
              const slug = slugify(project.url, { lower: true })
              const alt = i % 2 ? true : false

              return (
                <Grid item xs={12} key={project.id}>
                  <Grid
                    container
                    item
                    direction={alt ? "row-reverse" : "row"}
                    justifyContent="center"
                    xs={12}
                  >
                    <Grid item md={12} lg={7}>
                      <Grid
                        container
                        justify={
                          matchesMD ? "center" : alt ? "flex-start" : "flex-end"
                        }
                      >
                        <GatsbyImage
                          image={image}
                          className={
                            alt
                              ? classes.altProjectImageCtr
                              : classes.projectImageCtr
                          }
                          alt={project.title}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={5}
                      className={
                        alt ? classes.altProjectTxtCtr : classes.projectTxtCtr
                      }
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        lg={9}
                        xl={6}
                        className={classes.projectAboutMargin}
                        style={{
                          marginLeft: alt ? "auto" : undefined,
                        }}
                        justify={matchesMD ? "center" : undefined}
                      >
                        <Typography
                          variant="h2"
                          align={matchesMD ? "center" : alt ? "right" : "left"}
                          className={classes.projectTitle}
                          style={{ transition: "0.5s", color: activeColor }}
                        >
                          {project.title}
                        </Typography>
                        {/* {project.shortDescription && (
                          <Typography
                            variant="body1"
                            align={matchesMD ? "center" : undefined}
                            className={classes.projectDescription}
                          >
                            {project.shortDescription}
                          </Typography>
                        )}
                        {projectLink && (
                          <Button
                            variant="contained"
                            className={
                              alt ? classes.altProjectBtn : classes.projectBtn
                            }
                            component={Link}
                            onMouseEnter={() =>
                              setActiveColor(theme.palette.primary.main)
                            }
                            onMouseLeave={() => setActiveColor(null)}
                            to={`/projects/${slug}`}
                          >
                            View Project
                          </Button>
                        )} */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.projectDivider} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </SRLWrapper>
  )
}
