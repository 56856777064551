import React from "react"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import ProjectsIndex from "../components/ProjectsIndex"

import CallToAction from "../components/CallToAction"

export default function ProjectsPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Projects"
        description="View samples of top rated ponds, waterfalls, and hardscapes that Andreatta Waterscapes has designed. Contact us for expert ponds, streams, and waterfalls!"
      />
      <ProjectsIndex />
      <CallToAction end={true} />
    </Layout>
  )
}
